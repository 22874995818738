import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import TripOptions from './components/TripOptions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/trip-planner/Amsterdam/Berlin" />} />
        <Route path="/trip-planner" element={<Navigate to="/trip-planner/Amsterdam/Berlin" replace />} />
        <Route path="/trip-planner/:origin/:destination/:departure/:return" element={<TripOptions />} />
        <Route path="/trip-planner/:origin/:destination/:departure" element={<TripOptions />} />
        <Route path="/trip-planner/:origin/:destination" element={<TripOptions />} />
        <Route path="*" element={<div>Er ging iets mis bij het overnemen van de ingestelde parameters door de Trip Planner (404).</div>} />
      </Routes>
    </Router>
  );
}

export default App;
