import React, { useEffect, useState, useRef, useCallback } from 'react';
import CityPicker from './CityPicker';
import { useNavigate, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import el from "date-fns/locale/nl";
import axios from 'axios';
import moment from 'moment';
import stationMappings from '../stations.json';
import '../App.css';
import { FaExchangeAlt, FaTrain } from 'react-icons/fa';

registerLocale("nl", el);

// Helper function to parse a date string in dd-mm-yyyy format
function parseDate(dateStr) {
  const [day, month, year] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day); // month is zero-based
}

function formatDate(inputDate) {
  // Check if the input is null, undefined, or in an incorrect format using a simple regex
  const datePattern = /^\d{2}-\d{2}-\d{4}$/;
  
  if (!inputDate || inputDate === "autoDDMMYYYY" || !datePattern.test(inputDate)) {
    if (inputDate !== "autoDDMMYYYY") {
      console.log(inputDate);
      console.warn('Invalid or null date. Setting default date.');
    } else {
      // Force dd-MM-yyyy pattern
      return moment().add(2, 'months').format('DD-MM-YYYY');
    }
    
    // Set the default date to two months from today
    const defaultDate = moment().add(2, 'months').format('MM-DD-YYYY');
    return defaultDate;
  }
  
  // Split the date into day, month, and year
  const [day, month, year] = inputDate.split('-');

  // Validate day, month, and year values
  if (parseInt(day, 10) > 31 || parseInt(day, 10) < 1 ||
      parseInt(month, 10) > 12 || parseInt(month, 10) < 1 ||
      year.length !== 4)
  {
    // Set the default date to two months from today
    const defaultDate = moment().add(2, 'months').format('MM-DD-YYYY');
    return defaultDate;
  }

  // Reformat the date to MM-DD-YYYY
  const reformattedDate = `${month}-${day}-${year}`;

  return reformattedDate;
}

// Add these utility classes/constants at the top of the file, outside the component
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const MAX_CONCURRENT_REQUESTS = 3;
const MAX_RETRIES = 3;
const BASE_RETRY_DELAY = 1000;
const MAX_RETRY_DELAY = 10000;

class RequestQueue {
  constructor() {
    this.queue = [];
    this.activeRequests = 0;
  }

  async add(request) {
    if (this.activeRequests >= MAX_CONCURRENT_REQUESTS) {
      // Wait for a slot to open up
      await new Promise(resolve => this.queue.push(resolve));
    }

    this.activeRequests++;
    try {
      return await request();
    } finally {
      this.activeRequests--;
      if (this.queue.length > 0) {
        const next = this.queue.shift();
        next();
      }
    }
  }
}

class TripCache {
  constructor() {
    this.cache = new Map();
  }

  get(key) {
    const cached = this.cache.get(key);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      return cached.data;
    }
    return null;
  }

  set(key, data) {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  clear() {
    this.cache.clear();
  }
}

// Move this before the TripOptions component definition, near other constants
const stationNameMapping = {};

// Keep the simple unslugify implementation
function unslugify(slug) {
    if (stationNameMapping[slug]) {
        return stationNameMapping[slug];
    }

    // Fallback: replace hyphens with spaces and capitalize each word
    return slug
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

const TripOptions = () => {
  const { origin: paramOrigin, destination: paramDestination, departure: paramDeparture, return: paramReturn } = useParams();
  const navigate = useNavigate();
  const [origin, setOrigin] = useState(paramOrigin || 'Amsterdam');
  const [destination, setDestination] = useState(paramDestination || 'Berlin');
  const [tripOptions, setTripOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nsOrigin, setNsOrigin] = useState('');
  const [nsDestination, setNsDestination] = useState('');
  const [sortOption, setSortOption] = useState('best');
  const [loadingAvailability, setLoadingAvailability] = useState({});
  const [availableTrips, setAvailableTrips] = useState({});
  const [journeyWidthInPixels, setJourneyWidthInPixels] = useState(0);
  const [visibleVendorBoxes, setVisibleVendorBoxes] = useState({});
  const [showMoreOffers, setShowMoreOffers] = useState({});
  const [departureDate, setDepartureDate] = useState(paramDeparture || formatDate('autoDDMMYYYY'));
  const [returnDate, setReturnDate] = useState(paramReturn || formatDate('autoDDMMYYYY'));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [initialized, setInitialized] = useState(false);
  const journeyRef = useRef(null);
  const scrollRef = useRef(null);
  const vendorBoxRefs = useRef({});
  const departureDateRef = useRef(null);
  const returnDateRef = useRef(null);
  const uniqueTripsRef = useRef(new Set());
  const requestCountRef = useRef(0);
  const previousLastDepartureTimeRef = useRef(null);
  const [passengerCounts, setPassengerCounts] = useState({
    adults: 1,
    babies: 0,
    children4to5: 0,
    children6to11: 0,
    children12to14: 0,
    youth15: 0,
    youth16to25: 0,
    seniors: 0,
  });
  const [isViewingReturn, setIsViewingReturn] = useState(false);
  const [travelDate, setTravelDate] = useState(departureDate);
  const [departureDatePickerOpen, setDepartureDatePickerOpen] = useState(false);
  const [returnDatePickerOpen, setReturnDatePickerOpen] = useState(false);
  // Add these state variables at the top of your component
  const [mobileTooltipVisible, setMobileTooltipVisible] = useState(false);
  const [mobileTooltipText, setMobileTooltipText] = useState('');
  const [isLoadingMore, setIsLoadingMore] = useState(false); // Add this new state
  // At the top with other state declarations
  const [loadingState, setLoadingState] = useState('loading'); // 'loading', 'complete', 'hiding', 'hidden'

  // Add new refs for request management
  const requestQueueRef = useRef(new RequestQueue());
  const tripCacheRef = useRef(new TripCache());
  const abortControllerRef = useRef(null);

  // Add connection status state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // Add connection status monitoring
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  // Provide default values for local development. In production, this is passed by WordPress. 
  const tripPlannerData = window.tripPlannerData ?? {
    origin: "Amsterdam",
    destination: "Berlin",
    departureDate: formatDate('autoDDMMYYYY'),
    returnDate: (() => {
      const date = moment(formatDate('autoDDMMYYYY'), 'MM-DD-YYYY');
      return date.add(5, 'days').format('DD-MM-YYYY');
    })(),
    baseUrl: ''
  };

  const noPriceAPIOperators = ['EUROSTAR', 'SNCF'];

  // Add new state for tracking request phase
  const [requestPhase, setRequestPhase] = useState('initial'); // 'initial', 'more', or 'extra'

  useEffect(() => {
    if (!initialized) {
      const originalDepartureDate = paramDeparture || formatDate('autoDDMMYYYY');
      const autoReturnDate = moment(originalDepartureDate, 'DD-MM-YYYY').add(5, 'days').format('DD-MM-YYYY');
      
      let originalReturnDate = paramReturn || autoReturnDate;
      
      // Validate that returnDate is after departureDate
      const departureDate = moment(originalDepartureDate, 'DD-MM-YYYY');
      const returnDate = moment(originalReturnDate, 'DD-MM-YYYY');
      
      if (!returnDate.isValid() || returnDate.isSameOrBefore(departureDate)) {
        console.warn(`Invalid return date provided: ${paramReturn}. Using auto-calculated return date: ${autoReturnDate}.`);
        originalReturnDate = autoReturnDate;
      }
      
      setDepartureDate(originalDepartureDate);
      setReturnDate(originalReturnDate);
      
      // Set travelDate based on isViewingReturn
      setTravelDate(isViewingReturn ? originalReturnDate : originalDepartureDate);
      
      setInitialized(true);
    }
  }, [initialized, paramDeparture, paramReturn, isViewingReturn]);

  const baseUrl = tripPlannerData.baseUrl;

  // Add this at component level
  const shortTransferStationsRef = useRef(new Map());
  const isRetryWithLongerTransfersRef = useRef(false);

  // Modified fetchTripOptions with all improvements
  useEffect(() => {
    const fetchTripOptions = async (startDate, longerTransferStation = null) => {
      if (requestCountRef.current >= 15) {
        console.log("Too many requests, stopping [1]");
        handleLoadingComplete();
        return;
      }

      // Create new abort controller for this request cycle
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      const source = axios.CancelToken.source();

      try {
        if (!isOnline) {
          throw new Error('No internet connection');
        }

        // Set loading states based on request phase
        if (requestCountRef.current === 0) {
          setLoading(true);
          setRequestPhase('initial');
        } else if (!longerTransferStation) {
          setIsLoadingMore(true);
          setLoading(false);
          setRequestPhase('more');
        } else {
          setIsLoadingMore(true);
          setLoading(false);
          setRequestPhase('extra');
        }

        const originalOrigin = unslugify(origin);
        const originalDestination = unslugify(destination);
        // Modify the cache key to include the station parameter if it exists
        const cacheKey = `${originalOrigin}-${originalDestination}-${startDate}${longerTransferStation ? `-${longerTransferStation}` : ''}`;

        // Check cache first
        const cachedData = tripCacheRef.current.get(cacheKey);
        if (cachedData) {
          console.log("Loading from cache");
          handleTripData(cachedData);
          return;
        }

        // Implement retry logic with exponential backoff
        let retryCount = 0;
        let lastError = null;

        while (retryCount <= MAX_RETRIES) {
          try {
            const response = await requestQueueRef.current.add(async () => {
              // Create a moment object and format it to match the desired string format
              const dateString = moment(startDate)
                .format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
                .replace('GMT+0200', 'GMT+0200');  // Ensure consistent timezone format

              const params = {
                origin: originalOrigin,
                destination: originalDestination,
                date: dateString,
              };

              // Add longer transfer parameter if specified
              if (longerTransferStation) {
                params.longerTransferStation = longerTransferStation;
              }

              const result = await axios.get(`https://traincitytrip.eu/trip-options`, {
                params,
                cancelToken: source.token,
                signal: abortControllerRef.current.signal,
                timeout: 30000,
              });
              return result;
            });

            if (response?.data) {
              // Cache successful response
              tripCacheRef.current.set(cacheKey, response.data);
              
              // If no data is returned or empty array, stop making more requests
              if (!response.data.length) {
                console.log("No data returned, stopping requests");
                handleLoadingComplete();
                return;
              }
              
              // Process the response data
              handleTripData(response.data);
              return; // Exit the function after successful processing
            } else {
              // If no response data, stop making more requests
              console.log("No response data, stopping requests");
              handleLoadingComplete();
              return;
            }

          } catch (error) {
            lastError = error;
            if (axios.isCancel(error) || error.name === 'AbortError') {
              throw error; // Don't retry canceled requests
            }

            if (retryCount === MAX_RETRIES) {
              throw error; // Max retries reached
            }

            // Calculate delay with exponential backoff
            const delay = Math.min(
              BASE_RETRY_DELAY * Math.pow(2, retryCount),
              MAX_RETRY_DELAY
            );

            await new Promise(resolve => setTimeout(resolve, delay));
            retryCount++;
          }
        }

        // If we get here, all retries failed
        throw lastError || new Error('Failed to fetch trip options');

      } catch (error) {
        if (!axios.isCancel(error) && error.name !== 'AbortError') {
          console.error('Request failed:', error);
          handleLoadingComplete();
        }
      }
    };

    // Helper function to process trip data
    const handleTripData = (data) => {
      if (data.length > 0) {
        const firstTrip = data[0];
        const originStation = firstTrip.legs[0].origin;
        const destinationStation = firstTrip.legs[firstTrip.legs.length - 1].destination;

        setNsOrigin(getNSEncoding(originStation));
        setNsDestination(getNSEncoding(destinationStation));

        const lastTrip = data[data.length - 1];
        const lastDepartureTime = moment(lastTrip.departure);
        const inputDate = moment(travelDate, 'DD-MM-YYYY', true);

        // Check if we're getting trips from a different day
        if (!lastDepartureTime.isSame(inputDate, 'day')) {
          // If we're in the extra phase, this means we've completed the current station
          if (requestPhase === 'extra' || isRetryWithLongerTransfersRef.current) {
            console.log("Different day reached in extra phase, moving to next station or completing");
            // Remove handleLoadingComplete from here and just return
            return;
          }

          if (!isRetryWithLongerTransfersRef.current && shortTransferStationsRef.current.size > 0) {
            // Only collect problematic stations during initial and 'more' phases
            if (requestPhase !== 'extra') {
              // Get top 2 problematic stations
              const sortedStations = Array.from(shortTransferStationsRef.current.entries())
                .sort((a, b) => b[1] - a[1])
                .slice(0, 2)
                .map(([station]) => station);

              // Set flag to prevent original API calls
              isRetryWithLongerTransfersRef.current = true;

              // Reset time to start of day and retry with problematic stations
              const startOfDay = moment(inputDate).startOf('day');
              
              // Make requests sequential for each station
              const makeStationRequests = async (stationIndex) => {
                if (stationIndex >= sortedStations.length) {
                  console.log("All stations processed, completing");
                  handleLoadingComplete(); // Move handleLoadingComplete here
                  return;
                }

                const currentStation = sortedStations[stationIndex];
                // Reset the previousLastDepartureTimeRef for each new station
                previousLastDepartureTimeRef.current = null;
                
                const makeNextRequest = async (startTime) => {
                  const lastTime = previousLastDepartureTimeRef.current;
                  await fetchTripOptions(startTime, currentStation);
                  
                  // If previousLastDepartureTimeRef hasn't changed, it means we got cached data
                  // or reached a different day
                  if (lastTime === previousLastDepartureTimeRef.current || 
                      (previousLastDepartureTimeRef.current && 
                       !moment(previousLastDepartureTimeRef.current).isSame(inputDate, 'day'))) {
                    // Move to next station
                    await makeStationRequests(stationIndex + 1);
                    return;
                  }

                  // Continue with the same station if we're still on the same day
                  if (requestCountRef.current < 15) {
                    const nextStartTime = moment(previousLastDepartureTimeRef.current).add(2, 'hours');
                    await makeNextRequest(nextStartTime);
                  } else {
                    // Hit request limit, move to next station
                    await makeStationRequests(stationIndex + 1);
                  }
                };

                // Start the chain of requests for this station
                await makeNextRequest(startOfDay);
              };
              
              // Start with the first station
              makeStationRequests(0);
              return;
            }
          }
          
          // Only call handleLoadingComplete if we're in the extra phase
          if (requestPhase === 'extra' || isRetryWithLongerTransfersRef.current) {
            console.log("Extra phase complete");
            handleLoadingComplete();
          }
          return;
        }

        // Only stop if we're getting trips from a previous request
        if (previousLastDepartureTimeRef.current && 
            lastDepartureTime.isSameOrBefore(moment(previousLastDepartureTimeRef.current))) {
            console.log("Loading complete - duplicate results");
            return;
        }

        const newTrips = data.filter(trip => {
            const tripKey = `${trip.departure}-${trip.arrival}`;
            if (uniqueTripsRef.current.has(tripKey)) {
              return false;
            }
            uniqueTripsRef.current.add(tripKey);
            trip.complexity = calculateComplexity(trip, shortTransferStationsRef);
            return true;
        });

        setTripOptions(prevOptions => [...prevOptions, ...newTrips]);
        previousLastDepartureTimeRef.current = lastDepartureTime.toISOString();
        requestCountRef.current += 1;

        // Only continue with regular requests if we're not in extra mode
        if (!isRetryWithLongerTransfersRef.current) {
          const nextStartDate = lastDepartureTime.add(2, 'hours');
          if (nextStartDate && requestCountRef.current < 15) {
            fetchTripOptions(nextStartDate, data.longerTransferStation);
          } else {
            console.log("Loading complete - max requests reached");
            handleLoadingComplete();
          }
        }
      } else {
        // Only complete if we're in the extra phase or if there are no problematic stations
        if (requestPhase === 'extra' || isRetryWithLongerTransfersRef.current || shortTransferStationsRef.current.size === 0) {
          console.log("No trips found, stopping");
          handleLoadingComplete();
        }
      }
    };

    const fetchInitialTrips = async () => {
      if (!travelDate || typeof travelDate === 'object') {
        return;
      }

      setLoading(true);
      tripCacheRef.current.clear(); // Clear cache on new search
      uniqueTripsRef.current.clear();
      requestCountRef.current = 0;

      try {
        const initialDate = moment(travelDate, 'DD-MM-YYYY');
        await fetchTripOptions(initialDate);
      } catch (error) {
        console.error('Error in fetchInitialTrips:', error);
        handleLoadingComplete();
      }
    };

    fetchInitialTrips();

    // Cleanup function
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [origin, destination, travelDate, isOnline]);

  const checkTicketAvailability = useCallback(async (trip) => {
    const compositeKey = createCompositeKey(trip.departure, trip.arrival);
    setLoadingAvailability(prev => ({ ...prev, [compositeKey]: true }));

    try {
      const incompatibleOperators = ['operator1', 'operator2'];
      const dbOnlyOperators = ['FlixTrain', 'European Sleeper'];
      let vendors = [];

      // Determine vendor
      const priceIsKnown = trip.price?.amount !== undefined && trip.price.amount >= 0;
      
      const isTripInNL = nsOrigin.startsWith('NL') || nsDestination.startsWith('NL');

      if (
        isTripInNL && 
        !trip.legs.some(leg => incompatibleOperators.includes((leg.operator).split(',', 1)[0])) &&
        !trip.legs.some(leg => dbOnlyOperators.includes((leg.operator).split(',', 1)[0]))
      ) {
        vendors.push('NS');
      }

      if (priceIsKnown &&
        !trip.legs.some(leg => "European Sleeper".includes((leg.operator).split(',', 1)[0]))
      ) {
        vendors.push('DB');
      }

      if (trip.legs.length === 1 && trip.legs[0].operator === "European Sleeper") {
        vendors.push('European Sleeper');
      }

      let isAvailable = (vendors.length === 0);

      const bookingUrls = [];

      /**
       * Generates the passenger parameter string based on the operator and passenger counts.
       * 
       * @param {Object} passengerCounts - The counts of different passenger categories.
       * @param {string} operator - The name of the operator (e.g., 'NS', 'DB', 'European Sleeper').
       * @returns {string} - The formatted passenger parameter string.
       */
      const generatePaxParameter = (passengerCounts, operator) => {
        const { adults, babies, children4to5, children6to11, children12to14, youth15, youth16to25, seniors } = passengerCounts;
        let pax = [];

        if (operator === 'NS') {
          // NS Parameter Mapping
          if (babies > 0) pax.push(...Array(babies).fill('C_0'));
          if (children4to5 > 0) pax.push(...Array(children4to5).fill('C_4'));
          if (children6to11 > 0) pax.push(...Array(children6to11).fill('C_6'));
          if (children12to14 > 0) pax.push(...Array(children12to14).fill('Y_12'));
          if (youth15 > 0) pax.push(...Array(youth15).fill('Y_15'));
          if (youth16to25 > 0) pax.push(...Array(youth16to25).fill('Y'));
          if (adults > 0) pax.push(...Array(adults).fill('A'));
          if (seniors > 0) pax.push(...Array(seniors).fill('S_65'));

          return pax.join(',');
        } else if (operator === 'European Sleeper') {
          // European Sleeper Parameter Mapping
          let passengerTypes = [];

          // children4to5 and children6to11 map to PassengerTypes=73
          if (children4to5 > 0) {
            passengerTypes.push(...Array(children4to5).fill('73'));
          }
          if (children6to11 > 0) {
            passengerTypes.push(...Array(children6to11).fill('73'));
          }

          // children12to14, youth15, youth16to25, adults, and seniors map to PassengerTypes=72
          const totalAdults = children12to14 + youth15 + youth16to25 + adults + seniors;
          if (totalAdults > 0) {
            passengerTypes.push(...Array(totalAdults).fill('72'));
          }

          // Construct the passengerTypes query parameters
          return passengerTypes.map(type => `passengerTypes=${type}`).join('&');
        } else if (operator === 'DB') {
          let dbPax = [];
          
          // Adults (27-64)
          if (adults > 0) {
            dbPax.push(`13:16:KLASSENLOS:${adults}`);
          }
          
          // Seniors (65+)
          if (seniors > 0) {
            dbPax.push(`12:16:KLASSENLOS:${seniors}`);
          }
          
          // Youth (15-26)
          const totalYouth = youth15 + youth16to25;
          if (totalYouth > 0) {
            dbPax.push(`9:16:KLASSENLOS:${totalYouth}`);
          }
          
          // Children (6-14)
          const totalChildren6to14 = children6to11 + children12to14;
          if (totalChildren6to14 > 0) {
            dbPax.push(`11:16:KLASSENLOS:${totalChildren6to14}`);
          }
          
          // Children (0-5)
          const totalChildren0to5 = babies + children4to5;
          if (totalChildren0to5 > 0) {
            dbPax.push(`8:16:KLASSENLOS:${totalChildren0to5}`);
          }
          
          return `r=${dbPax.join(',')}`;
        }

        // Default fallback if operator is unknown
        console.warn(`Unknown operator "${operator}". Returning empty passenger parameter.`);
        return '';
      };

      if (vendors.includes('NS')) {
        if (nsOrigin === "NS-MAPPING-ERROR" || nsDestination === "NS-MAPPING-ERROR") {
          // Instead of setting isAvailable to false, we'll just filter out NS from vendors
          vendors = vendors.filter(v => v !== 'NS');
        } else {
          const formattedDate = moment(travelDate, 'DD-MM-YYYY').format('YYYYMMDD');
          const formattedDepartureTime = format(new Date(trip.departure), 'HHmm');
          const formattedArrivalTime = format(new Date(trip.arrival), 'HHmm');

          const paxParameter = generatePaxParameter(passengerCounts, 'NS');
          const directBookingUrl = `https://www.nsinternational.com/nl/treintickets-v3/#/search/${nsOrigin}/${nsDestination}/${formattedDate}/${formattedDepartureTime}/${formattedArrivalTime}?pax=${paxParameter}`;
          const ttBase = "https://www.nsinternational.com/traintracker/"
          const refCode = "943_35043_456543_";
          bookingUrls['NS'] = `${ttBase}?tt=${refCode}&r=${encodeURIComponent(directBookingUrl)}`;
        }
      }
      
      if (vendors.includes('DB')) {
        const formattedDate = format(new Date(trip.departure), 'yyyy-MM-dd');
        const formattedTime = format(new Date(trip.departure), 'HH:mm:ss');
        const encodedOrigin = encodeURIComponent(trip.legs[0].origin);
        const encodedDestination = encodeURIComponent(trip.legs[trip.legs.length - 1].destination);

        // Calculate shortest transfer time
        const shortestTransfer = trip.legs.length > 1 
          ? Math.min(...trip.legs.slice(1).map((leg, index) => {
              const prevLeg = trip.legs[index];
              return Math.floor((new Date(leg.departure) - new Date(prevLeg.arrival)) / (1000 * 60)); // Convert to minutes
            }))
          : 0;

        // Round down to nearest valid transfer time
        const validTransferTime = getValidTransferTime(shortestTransfer);

        const awId = "1693255";
        let dbDirectBookingUrl = `https%3A%2F%2Fint.bahn.de%2Fen%2Fbuchung%2Fstart%3Flang%3Dnl%26sts%3Dfalse%26so%3D${encodedOrigin}%26zo%3D${encodedDestination}%26hd%3D${formattedDate}T${formattedTime}`;

        // Add transfer station parameter if 'via' field is present
        if (trip.via) {
          // Find actual transfer stations in the trip
          const transferStations = trip.legs.slice(1).map(leg => leg.origin);
          
          // Find if there's a similar station to the suggested 'via' station
          let actualTransferStation = trip.via;
          if (!transferStations.includes(trip.via)) {
            // First normalize station names by adding spaces around parentheses and standardizing format
            const normalizeStation = (station) => {
              return station
                .replace(/\(/g, ' (')
                .replace(/\)/g, ') ')
                .replace(/\s+/g, ' ')  // Replace multiple spaces with single space
                .replace(/Hbf/g, 'Hauptbahnhof')  // Standardize Hbf to Hauptbahnhof
                .trim();
            };

            const normalizedVia = normalizeStation(trip.via);
            const normalizedTransferStations = transferStations.map(normalizeStation);
            
            // Try to find a similar station by comparing first word (city name)
            const similarStation = transferStations.find((station, index) => 
              normalizedVia.split(' ')[0] === normalizedTransferStations[index].split(' ')[0] // Compare first word (e.g., "Frankfurt")
            );
            
            if (similarStation) {
              actualTransferStation = similarStation;
            }
          }

          // Create the transfer station parameter with the actual transfer station
          const transferStation = encodeURIComponent(`[["A=1@O=${actualTransferStation}@X=8663785@Y=50107149@U=80@L=8000105@B=1@p=1738178088@i=U×008011068@","${actualTransferStation}",35]]`);
          dbDirectBookingUrl += `%26hz%3D${transferStation}`;
        }

        // Add minimum transfer duration parameter if there are transfers
        if (shortestTransfer > 0) {
          dbDirectBookingUrl += `%26mud%3D${validTransferTime}`;
        }

        // Add remaining parameters
        dbDirectBookingUrl += `%26${generatePaxParameter(passengerCounts, 'DB')}%26dbkanal_003%3DL01_S01_D001_KAF0001_14964_Linkgenerator-individuelle-Verbindungen-INT-${awId}_LZ03`;

        const awBase = `https://www.awin1.com/cread.php?awinmid=14964&awinaffid=${awId}&linkid=3451566`;
        bookingUrls['DB'] = `${awBase}&p=${encodeURIComponent(dbDirectBookingUrl)}`;
      }

      // Filter vendors when 'via' field is present
      if (trip.via) {
        vendors = vendors.filter(vendor => vendor === 'DB');
        // Also filter bookingUrls to only keep DB
        Object.keys(bookingUrls).forEach(key => {
          if (key !== 'DB') {
            delete bookingUrls[key];
          }
        });
      }

      if (vendors.includes('European Sleeper')) {
        if (trip['booking-page']) {  // Only proceed if booking-page exists
          const directBookingUrl = `https://www.europeansleeper.eu/train/`;
          const refCode = "37347_2209752_456543_";
          
          // Replace the default passenger types with the generated ones
          let modifiedBookingPage = trip['booking-page'].replace(
            /passengerTypes=72/g,  // Replace all occurrences
            generatePaxParameter(passengerCounts, 'European Sleeper')
          );
          
          const bookingPage = encodeURIComponent(modifiedBookingPage);
          bookingUrls['European Sleeper'] = `${directBookingUrl}?tt=${refCode}&r=${bookingPage}`;
        }
        // If booking-page is undefined, bookingUrls['European Sleeper'] will not be set
      }

      setAvailableTrips(prev => ({
          ...prev,
          [compositeKey]: {
              isAvailable,
              vendors,
              bookingUrls
          }
      }));
    } catch (error) {
      console.error('Error checking ticket availability:', error);
      setAvailableTrips(prev => ({ ...prev, [compositeKey]: { isAvailable: false, vendors: [], bookingUrls: [] } }));
    } finally {
      setLoadingAvailability(prev => ({ ...prev, [compositeKey]: false }));
    }
  }, [nsOrigin, nsDestination, passengerCounts, travelDate]);

  useEffect(() => {
    if (tripOptions.length > 0) {
      tripOptions.forEach((trip) => {
        checkTicketAvailability(trip);
      });
    }
  }, [tripOptions, checkTicketAvailability]);

  useEffect(() => {
    if (loading) return;
  
    const adjustLegDetails = () => {
      const measureWidth = () => {
        if (journeyRef.current) {
          setJourneyWidthInPixels(journeyRef.current.offsetWidth);
        }
      };

      measureWidth();
      setIsMobile(window.innerWidth <= 767);
      const journeyLegs = document.querySelectorAll('.journey-leg');
  
      journeyLegs.forEach(journeyLeg => {
        const legDetails = journeyLeg.querySelector('.leg-details');
        const dashedLine = journeyLeg.querySelector('.dashed-line');
  
        if (legDetails && dashedLine) {
          const isWalking = legDetails.querySelector('.walking-icon');
          if (isWalking) return;
  
          legDetails.classList.remove('hidden', 'hide-text');
  
          const legDetailsWidth = legDetails.offsetWidth;
          const dashedLineWidth = dashedLine.offsetWidth;
  
          if (!isMobile) {
            if (legDetailsWidth > 0.95 * dashedLineWidth) {
              legDetails.classList.add('hidden');
              legDetails.classList.remove('hide-text');
            } else if (legDetailsWidth > 0.75 * dashedLineWidth) {
              legDetails.classList.add('hide-text');
              legDetails.classList.remove('hidden');
            } else {
              legDetails.classList.remove('hidden', 'hide-text');
            }
          } else {
            legDetails.classList.remove('hidden', 'hide-text'); /* Mobile styles */
          }
        }
      });
    };
  
    adjustLegDetails();
    window.addEventListener('resize', adjustLegDetails);
  
    return () => {
      window.removeEventListener('resize', adjustLegDetails);
    };
  }, [loading, tripOptions, isMobile]);

  const createCompositeKey = (departure, arrival) => {
    return `${departure}_${arrival}`;
  };

  // Modify the scrollToSearchRef function
  const scrollToSearchRef = () => {
    // Only scroll if this is the initial load (no results yet)
    if (tripOptions.length === 0 && scrollRef.current) {
      const topPosition = scrollRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  const resetState = () => {
    setLoading(true);
    setInitialized(false);
    previousLastDepartureTimeRef.current = null;
    setTripOptions([]);
    requestCountRef.current = 0;
    uniqueTripsRef.current = new Set();

    scrollToSearchRef();
  };

  // Function to slugify and store the mapping
  function slugifyWithMapping(text) {
    const slugified = text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9\s-]/g, '')
      .trim()
      .replace(/\s+/g, '-')
      .toLowerCase();

    // Store the mapping
    stationNameMapping[slugified] = text;
    return slugified;
  }

  // Define handlers to update state based on selected city
  const handleOriginChange = (selectedOption) => {
    if (selectedOption) {
      setIsViewingReturn(false);  // Reset to outbound journey view
      setOrigin(selectedOption.value);
      setTravelDate(departureDate);

      const slugifiedOrigin = slugifyWithMapping(selectedOption.value);
      const slugifiedDestination = slugifyWithMapping(destination);
      const slugifiedDeparture = slugify(departureDate);
      const slugifiedReturn = slugify(returnDate);

      resetState();
      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDeparture}/${slugifiedReturn}`);
    }
  };

  const handleDestinationChange = (selectedOption) => {
    if (selectedOption) {
      setIsViewingReturn(false);  // Reset to outbound journey view
      setDestination(selectedOption.value);
      setTravelDate(departureDate);

      const slugifiedOrigin = slugifyWithMapping(origin);
      const slugifiedDestination = slugifyWithMapping(selectedOption.value);
      const slugifiedDeparture = slugify(departureDate);
      const slugifiedReturn = slugify(returnDate);

      resetState();
      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDeparture}/${slugifiedReturn}`);
    }
  };

  const handleDateDelta = (isReturn, daysDelta, fromNow = false) => {
    let baseDate;

    if (isReturn) {
      baseDate = fromNow ? new Date() : moment(returnDate, 'DD-MM-YYYY').toDate();
      const newDate = new Date(baseDate);
      newDate.setDate(newDate.getDate() + daysDelta);
      handleDateChange(newDate, true);
    } else {
      baseDate = fromNow ? new Date() : moment(departureDate, 'DD-MM-YYYY').toDate();
      const newDate = new Date(baseDate);
      newDate.setDate(newDate.getDate() + daysDelta);
      handleDateChange(newDate, false);
    }
  };

  const handleDateChange = (date, isReturn = false) => {
    if (date) {
      // Ensure we're working with a Date object
      const dateObj = date instanceof Date ? date : new Date(date);
      if (isNaN(dateObj.getTime())) {
        console.error("Invalid date:", date);
        return;
      }

      // Format the new date
      const newFormattedDate = format(dateObj, 'dd-MM-yyyy');

      // Determine if the currently viewed date is being changed
      const isCurrentView = (isReturn && isViewingReturn) || (!isReturn && !isViewingReturn);

      // Update the appropriate date state
      if (isReturn) {
        setReturnDate(newFormattedDate);
      } else {
        setDepartureDate(newFormattedDate);
      }

      // Determine the new departure and return dates for navigation
      const updatedDepartureDate = isReturn ? departureDate : newFormattedDate;
      const updatedReturnDate = isReturn ? newFormattedDate : returnDate;

      // Slugify all dynamic segments using the updated dates
      const slugifiedOrigin = slugify(origin);
      const slugifiedDestination = slugify(destination);
      const slugifiedDeparture = slugify(updatedDepartureDate);
      const slugifiedReturn = slugify(updatedReturnDate);

      // Navigate using the updated dates
      navigate(`/trip-planner/${slugifiedOrigin}/${slugifiedDestination}/${slugifiedDeparture}/${slugifiedReturn}`);

      // If the current view is active, update travelDate and reset state
      if (isCurrentView) {
        setTravelDate(newFormattedDate);
        resetState();
      }
    } else {
      console.error("Issue setting new date: {date}.");
    }
  };

  const handleDateDisplayClick = (ref) => {
    if (ref.current) {
      ref.current.setFocus();
    }
  };

  const handleSwapLocations = () => {
    // Get the current values from the CityPicker state
    const tempOrigin = origin;
    const tempDestination = destination;
    
    // Simply swap the values in state without any URL navigation
    setOrigin(tempDestination);
    setDestination(tempOrigin);
    
    resetState(); // Reset state to fetch new trip options
  };

  // Function to update passenger counts
  const updatePassengerCount = (category, delta) => {
    setPassengerCounts(prevCounts => ({
      ...prevCounts,
      [category]: Math.max(0, prevCounts[category] + delta) // Ensure count doesn't go below 0
    }));
  };

  // Function to calculate similarity between two strings
  const similarity = (s1, s2) => {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    const longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  };

  // Function to determine if a location matches any of the specified locations
  const isFuzzyMatch = (location, targets) => {
    return targets.some(target => similarity(location.toLowerCase(), target.toLowerCase()) > 0.8);
  };

  // Function to get highlighted dates based on origin and destination
  const getHighlightedDates = (origin, destination) => {
    const highlightDates = [];
    const targetLocations = ['BrenneroBrenner', 'bressanone', 'Ponte GardenaWaidbruck', 'BolzanoBozen', 'Verona', 'Verona Porta Nuova', 'Venezia', 'Venetië Mestre', 'Venetië Santa Lucia'];

    if (isFuzzyMatch(destination, targetLocations)) {
      highlightDates.push(parseDate('05-02-2025'), parseDate('14-02-2025'), parseDate('04-03-2025'), parseDate('12-03-2025'));
    }

    if (isFuzzyMatch(origin, targetLocations)) {
      highlightDates.push(parseDate('09-02-2025'), parseDate('18-02-2025'), parseDate('08-03-2025'), parseDate('16-03-2025'));
    }

    return highlightDates;
  };

  // Function to determine the class for each day
  const getDayClassName = (date, selectedDate, highlightDates) => {
    if (!selectedDate) return ''; // Return empty string if selectedDate is null or undefined

    const selectedDateObj = moment(selectedDate, 'DD-MM-YYYY').toDate(); // Ensure selectedDate is a Date object

    const isSameMonthAndYear = date.getMonth() === selectedDateObj.getMonth() &&
                               date.getFullYear() === selectedDateObj.getFullYear();

    const isSelectedDate = date.getDate() === selectedDateObj.getDate() && isSameMonthAndYear;

    // Check if the date is part of highlightDates
    const isHighlighted = highlightDates.some(highlightDate => {
      const highlightDateObj = moment(highlightDate, 'DD-MM-YYYY').toDate();
      return date.getTime() === highlightDateObj.getTime();
    });

    // Apply styling for highlightDates with priority
    if (isHighlighted) {
      return 'highlight-date';
    }

    // Apply styling only if the date is the selected date of the selected month
    if (isSelectedDate) {
      return 'highlight-selected-date';
    }

    return '';
  };

  // Helper function to get complexity label
  const getComplexityLabel = (value) => {
    switch (value) {
      case 1:
        return "Geschikt voor beginners";
      case 2:
        return "Iets moeilijkere reis";
      case 3:
        return "Complexe reis";
      default:
        return "Geschikt voor beginners";
    }
  };

  // Add these helper functions if they're not already defined elsewhere in the file
  const formatDuration = (trip) => {
    const duration = (new Date(trip.arrival) - new Date(trip.departure)) / (1000 * 60);
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.complexity-clickable')) {
        setMobileTooltipVisible(false);
      }
    };

    const handleScroll = () => {
      setMobileTooltipVisible(false);
    };

    document.addEventListener('click', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Add this new helper function
  const handleLoadingComplete = () => {
    setLoadingState('complete');
    setTimeout(() => {
      setLoadingState('hiding');
      setTimeout(() => {
        setLoadingState('hidden');
        setLoading(false);
        setIsLoadingMore(false);
      }, 200); // Reduced from 400ms to 200ms
    }, 3000);
  };

  // Calculate shortest transfer time and round down to nearest allowed value
  const getValidTransferTime = (minutes) => {
    const allowedValues = [10, 15, 20, 25, 30, 35, 40, 45];
    // Find the largest allowed value that is less than or equal to the actual transfer time
    return allowedValues.reverse().find(value => value <= minutes) || 10; // Default to 10 if transfer time is < 10
  };

  return (
    <div>
      <div className="tripplanner-header">
        <h2>Reisplanner (bèta)</h2>
        <div className="search-form">
          <div className="key-input is-active search-stations" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            <div className="station-row" style={{ flex: '1 1 45%', display: 'flex', alignItems: 'center' }}>
              <label className="input-label" style={{ visibility: 'hidden' }}>Van</label>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <FaTrain className="train-icon" />
                <div className="arrow-right"></div>
              </div>
              <CityPicker onSelect={handleOriginChange} placeholder={origin} />
            </div>
            <div className="swap-icon-container" style={{ flex: '0 0 auto', margin: '0 10px' }}>
              <FaExchangeAlt className="swap-icon" onClick={handleSwapLocations} />
            </div>
            <div className="station-row" style={{ flex: '1 1 45%', display: 'flex', alignItems: 'center' }}>
              <label className="input-label" style={{ visibility: 'hidden' }}>Naar</label>
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <FaTrain className="train-icon" />
                <div className="arrow-left"></div>
              </div>
              <CityPicker onSelect={handleDestinationChange} placeholder={destination} />
            </div>
          </div>
          <div className="key-input is-active search-dates">
            <div className="date-rows-container">
              <div className={`date-row ${!isViewingReturn ? 'is-active' : ''}`}>
                <div className="previousDayIn" onClick={() => handleDateDelta(false, -1)}>&lsaquo;</div>
                <div className="date-display" onClick={() => handleDateDisplayClick(departureDateRef)}>
                  <label className="input-label">Heen</label>
                  <DatePicker
                    ref={departureDateRef}
                    selected={departureDate ? moment(departureDate, 'DD-MM-YYYY').toDate() : null}
                    onChange={(date) => {
                      handleDateChange(date, false);
                      setDepartureDatePickerOpen(false);
                    }}
                    value={getFormattedDateShorthand(departureDate)}
                    locale="nl"
                    open={departureDatePickerOpen}
                    onClickOutside={() => setDepartureDatePickerOpen(false)}
                    onInputClick={() => setDepartureDatePickerOpen(true)}
                    highlightDates={getHighlightedDates(origin, destination)} // Highlight for departure
                    dayClassName={(date) => getDayClassName(date, departureDate, getHighlightedDates(origin, destination))} // Pass highlightDates
                  />
                </div>
                <div className="nextDayIn" onClick={() => handleDateDelta(false, 1)}>&rsaquo;</div>
              </div>
              <div className={`date-row ${isViewingReturn ? 'is-active' : ''}`}>
                <div className="previousDayOut" onClick={() => handleDateDelta(true, -1)}>&lsaquo;</div>
                <div className="date-display" onClick={() => handleDateDisplayClick(returnDateRef)}>
                  <label className="input-label">Terug</label>
                  <DatePicker
                    ref={returnDateRef}
                    selected={returnDate ? moment(returnDate, 'DD-MM-YYYY').toDate() : null}
                    onChange={(date) => {
                      handleDateChange(date, true);
                      setReturnDatePickerOpen(false);
                    }}
                    value={getFormattedDateShorthand(returnDate)}
                    locale="nl"
                    open={returnDatePickerOpen}
                    onClickOutside={() => setReturnDatePickerOpen(false)}
                    onInputClick={() => setReturnDatePickerOpen(true)}
                    highlightDates={getHighlightedDates(destination, origin)} // Highlight for return, swap origin and destination
                    dayClassName={(date) => getDayClassName(date, returnDate, getHighlightedDates(destination, origin))} // Pass highlightDates
                  />
                </div>
                <div className="nextDayOut" onClick={() => handleDateDelta(true, 1)}>&rsaquo;</div>
              </div>
            </div>
            <div className="break"></div>
            <div className="moveDateAhead">
              <span className="tip">Tip &mdash; zoek goedkoop: </span>
              <span className="dateAheadButton" onClick={() => handleDateDelta(false, 60, true)}>nu + 2 maanden</span>
              <span className="dateAheadButton" onClick={() => handleDateDelta(false, 90, true)}>nu + 3 maanden</span>
              {returnDate && (
                <span 
                  className="dateAheadButton"
                  onClick={() => {
                    handleSwapLocations();
                    setIsViewingReturn(!isViewingReturn);
                    setTravelDate(!isViewingReturn ? returnDate : departureDate);
                    resetState();
                  }}
                >
                  {isViewingReturn ? "bekijk heenreis" : "bekijk terugreis"}
                </span>
              )}
            </div>
          </div>
          <div className="key-input is-active search-passengers">
            <div className="passenger-selector">
              <div className="passenger-sub-categories">
                <div className="passenger-sub-category">
                  <div className="circle-container">
                    <button 
                      className="passenger-button large" 
                      onClick={() => updatePassengerCount('adults', -1)}
                      disabled={passengerCounts.adults <= 0}
                    >-</button>
                    <div className="passenger-circle">{passengerCounts.adults}</div>
                    <button 
                      className="passenger-button large" 
                      onClick={() => updatePassengerCount('adults', 1)}
                      disabled={passengerCounts.adults >= 9}
                    >+</button>
                  </div>
                  <span className="passenger-label">Volwassene<br/><span className="age-range">26 - 59</span></span>
                </div>
                {[
                  { label: 'Baby', age: '0 - 3', key: 'babies' },
                  { label: 'Kind', age: '4 - 5', key: 'children4to5' },
                  { label: 'Kind', age: '6 - 11', key: 'children6to11' },
                  { label: 'Kind', age: '12 - 14', key: 'children12to14' },
                  { label: 'Jongere', age: '15', key: 'youth15' },
                  { label: 'Jongere', age: '16 - 25', key: 'youth16to25' },
                  { label: 'Senior', age: '65+', key: 'seniors' },
                ].map(({ label, age, key }) => (
                  <div key={key} className="passenger-sub-category">
                    <div className="circle-container">
                      <button 
                        className="passenger-button" 
                        onClick={() => updatePassengerCount(key, -1)}
                        disabled={passengerCounts[key] <= 0}
                      >-</button>
                      <div className="passenger-circle-small" data-value={passengerCounts[key]}>{passengerCounts[key]}</div>
                      <button 
                        className="passenger-button" 
                        onClick={() => updatePassengerCount(key, 1)}
                        disabled={passengerCounts[key] >= 9}
                      >+</button>
                    </div>
                    <span>{label}<br/><span className="age-range">{age}</span></span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div ref={scrollRef}></div>
      <div className="sort-buttons">
        <button className={`sort-button ${sortOption === 'best' ? 'active' : ''}`} onClick={() => setSortOption('best')}>Beste</button>
        <button className={`sort-button ${sortOption === 'cheapest' ? 'active' : ''}`} onClick={() => setSortOption('cheapest')}>Prijs</button>
        <button className={`sort-button ${sortOption === 'fastest' ? 'active' : ''}`} onClick={() => setSortOption('fastest')}>Snelste</button>
        <button className={`sort-button ${sortOption === 'chronological' ? 'active' : ''}`} onClick={() => setSortOption('chronological')}>Vertrek</button>
      </div>
      
      {(loading || isLoadingMore || loadingState !== 'hidden') && (
        <div className={`loading-more-overlay ${loadingState === 'hiding' ? 'hiding' : ''}`}>
          <div className="loading-more">
            <div className={`loading-indicator ${loadingState === 'complete' || loadingState === 'hiding' ? 'complete' : ''}`}>
              <div className="loading-spinner"></div>
              <svg className="checkmark" viewBox="0 0 24 24">
                <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />
              </svg>
            </div>
            <span>
              {(loadingState === 'complete' || loadingState === 'hiding') ? 'Alle opties geladen' : 
               tripOptions.length === 0 ? 'Reisopties laden...' : 
               requestPhase === 'extra' ? 'Extra reisopties laden...' :
               'Meer reisopties laden...'}
            </span>
          </div>
        </div>
      )}

      {!loading && !isLoadingMore && !tripOptions.length && (
        <div>Helaas konden we geen routes vinden op deze datum.</div>
      )}

      {tripOptions.length > 0 && (
        <div className="trip-container">
          {tripOptions
            .slice()
            .sort((a, b) => {
              const getDuration = (trip) => new Date(trip.arrival) - new Date(trip.departure);

              if (sortOption === 'best') {
                // Calculate effective duration with penalties based on legs
                const calculateEffectiveDuration = (trip) => {
                  const baseDuration = getDuration(trip);
                  const departureHour = new Date(trip.departure).getHours();
                  const arrivalHour = new Date(trip.arrival).getHours();
                  const nextDayArrival = () => {
                    return Math.ceil((new Date(trip.arrival) - new Date(trip.departure)) / (24 * 60 * 60 * 1000));
                  };

                  let penalty = 0;

                  const legs = Array.isArray(trip.legs) ? trip.legs : [];
                  
                  // Calculate transfer penalties
                  for (let i = 0; i < legs.length - 1; i++) {
                    const currentLeg = legs[i];
                    const nextLeg = legs[i + 1];
                    const transferDuration = new Date(nextLeg.departure) - new Date(currentLeg.arrival);
                    const transferHour = new Date(currentLeg.arrival).getHours();

                    if (transferDuration > 60 * 60 * 1000) { // over 1 hour
                      penalty += 0.2 * baseDuration + transferDuration;
                      if (transferHour >= 0 && transferHour < 7) {
                        penalty *= 4; // Long nightly transfer gives extra large penalty
                      }
                    } else {
                      penalty += 0.15 * baseDuration;
                      if (transferHour >= 0 && transferHour < 7) {
                        penalty *= 3; // Short nightly transfer gives large penalty
                      }
                    }

                    if (departureHour > 21 || departureHour < 6) {
                      penalty += 0.6 * baseDuration;
                    }

                    if (arrivalHour > 23 || arrivalHour < 7) {
                      penalty += 0.4 * baseDuration;
                    }

                    const daysArrivingNext = nextDayArrival();
                    if (daysArrivingNext > 0) {
                      penalty += 0.6 * daysArrivingNext * baseDuration;
                    }
                  }

                  return baseDuration + penalty;
                };

                const calculateScore = (trip) => {
                  const effectiveDuration = calculateEffectiveDuration(trip);
                  const price = trip.price?.amount || 350;
                  const priceWeight = 0.1;
                  const weightedPrice = price * priceWeight;
                  
                  const idealDepartureHour = 11;
                  const idealArrivalHour = 16;
                  
                  const actualDeparture = new Date(trip.departure).getHours() + new Date(trip.departure).getMinutes() / 60;
                  const actualArrival = new Date(trip.arrival).getHours() + new Date(trip.arrival).getMinutes() / 60;
                  
                  const departureDiff = Math.abs(actualDeparture - idealDepartureHour);
                  const arrivalDiff = Math.abs(actualArrival - idealArrivalHour);
                  
                  const timeWeight = 0.05;
                  const weightedTime = (departureDiff + arrivalDiff) * timeWeight;
                  
                  const complexityWeight = 0.5;  // Increased from 0.15 to 0.5
                  const complexity = trip.complexity?.value || 1;
                  const complexityPenalty = (complexity - 1) * complexityWeight * effectiveDuration;
                  
                  return effectiveDuration + weightedPrice + weightedTime + complexityPenalty;
                };

                return calculateScore(a) - calculateScore(b);

              } else if (sortOption === 'fastest') {
                // Sort by fastest journey (pure duration)
                return getDuration(a) - getDuration(b);

              } else if (sortOption === 'cheapest') {
                // Sort by cheapest journey
                const getPriceValue = (price) => {
                  const amount = price?.amount;
                  return amount < 0 || amount === undefined ? Infinity : amount;
                };

                return getPriceValue(a.price) - getPriceValue(b.price);
              } else {
                // Sort chronologically by departure time
                return new Date(a.departure) - new Date(b.departure);
              }
            })
            .map((trip, index) => {
              // Lookup additional details from availableTrips using the trip ID
              const compositeKey = createCompositeKey(trip.departure, trip.arrival);
              const tripDetails = availableTrips[compositeKey] || {};
              const { vendors, bookingUrls } = tripDetails;
              
              //const totalJourneyTime = calculateDurationPretty(trip.departure, trip.arrival);
              //const numTransfers = trip.legs.length - 1;
              //const longestTransfer = Math.max(...trip.legs.slice(1).map((leg, legIndex) => {
              //  // legIndex is already offset by slice, so use trip.legs[legIndex]
              //  const previousLeg = trip.legs[legIndex];
              //  return new Date(leg.departure) - new Date(previousLeg.arrival);
              //}));

              const SHORT_DURATION_THRESHOLD = calculateDuration(trip.departure, trip.arrival) * 0.06 * (1 / (journeyWidthInPixels / 800));
              const totalJourneyHeight = 400; // Total height in pixels for the journey container when in vertical layout
              const MIN_LINE_HEIGHT = 20; // Minimum height in pixels
              
              let tripHasHiddenStationNames = false;
              let previousShouldAddMarginStationName = false; // Track the previous leg's margin condition

              const containsSignificantSleeperTrain = trip.legs.some(leg => leg.train_number?.startsWith('NJ ') && calculateDuration(leg.departure, leg.arrival) > 4 * 60 * 60 * 1000) || // at least 4 hours on the nightjet
                trip.legs.some(leg => leg.train_number?.startsWith('ES ') && (leg.operator).split(',', 1)[0] === "European Sleeper" && calculateDuration(leg.departure, leg.arrival) > 4 * 60 * 60 * 1000) || // at least 4 hours on the ES train 
                trip.legs.some(leg => (leg.operator).split(',', 1)[0] === "Urlaubs-Express");

              const isBestOption = sortOption === 'best' && index === 0;

              return (
                <div className="trip-option" key={index}>
                  {/* Only show best option label when loading is complete */}
                  {isBestOption && loadingState === 'hidden' && (
                    <div className="best-option-label">Beste optie</div>
                  )}
                  {/* Grid Container for Existing Content */}
                  <div className="trip-option-content">
                    <div className="trip-time">
                      <span className="trip-time-city">{shorterStationName(trip.legs[0].origin)}</span>
                      {formatTime(trip.departure)}
                    </div>
                    <div className="trip-journey" ref={journeyRef}>
                      {trip.legs.map((leg, legIndex) => {
                        const tripDuration = calculateDuration(trip.departure, trip.arrival);
                        const legDuration = calculateDuration(leg.departure, leg.arrival);
                        const legDurationRatio = legDuration / tripDuration;
                        let legWidth = getWidth(legDuration, tripDuration); // Horizontal layout. 
                        const lineHeight = legIndex < trip.legs.length
                          ? Math.max(legDurationRatio * totalJourneyHeight, MIN_LINE_HEIGHT)
                          : 0; // Vertical layout. No line after the last leg
                        const isWalking = (leg.operator).split(',', 1)[0] === "N/A";
                        const transferDuration = legIndex > 0 ? calculateTransferTime(trip.legs[legIndex - 1].arrival, leg.departure) : 0;
                        const transferWidthInPixels = (transferDuration / tripDuration) * journeyWidthInPixels;
                        const transferWidthPercentage = (transferDuration / tripDuration) * 100;
                        const transferDurationRatio = transferDuration / tripDuration;
                        const transferHeight = transferDurationRatio * totalJourneyHeight;
                        const transfer = calculateTransferTimePretty(trip.legs[legIndex - 1]?.arrival, leg.departure);
                        const circleSize = 10;
                        const transferDetailsTop = circleSize + (lineHeight / 2);

                        // Determine transfer shape and width
                        const minTransferWidth = 30; // Add some margin
                        const minTransferHeight = 30;
                        const transferClass = !isMobile
                          ? (transferWidthInPixels > minTransferWidth ? 'rounded-rectangle' : 'circle-shape')
                          : (transferHeight > minTransferHeight ? 'rounded-rectangle-vertical' : 'circle-shape');

                        // Determine style for the circle
                        const circleStyle = {
                          width: `${circleSize}px`,  // Default width
                          height: `${circleSize}px`, // Default height
                        };
                        
                        // Adjust width and height based on transferClass
                        if (transferClass === 'rounded-rectangle') {
                          circleStyle.width = `${transferWidthPercentage}%`;
                        } else if (transferClass === 'rounded-rectangle-vertical') {
                          circleStyle.height = `${transferHeight}px`;
                        }

                        // Move leg label to the right when it is a long transfer
                        const moveLabelRight = {
                          marginLeft: transferClass === 'rounded-rectangle' ? `${transferWidthInPixels / 4 - 10}px` : '0',
                        };

                        // Update legWidth such that it includes the width of the transfer
                        legWidth += transferWidthPercentage;

                        // Calculate durations
                        const previousLegDuration = legIndex > 0 ? calculateDuration(trip.legs[legIndex - 1].departure, trip.legs[legIndex - 1].arrival) : Infinity;
                        const currentLegDuration = calculateDuration(leg.departure, leg.arrival);
                        const nextLegDuration = legIndex < trip.legs.length - 1 ? calculateDuration(trip.legs[legIndex + 1].departure, trip.legs[legIndex + 1].arrival) : Infinity;

                        // Determine if the margin should be added
                        const shouldAddMarginStationName = 
                          legIndex !== 0 && 
                          !previousShouldAddMarginStationName && (
                          previousLegDuration < SHORT_DURATION_THRESHOLD || 
                          currentLegDuration < (SHORT_DURATION_THRESHOLD * (leg.origin.length - 12)) || 
                          nextLegDuration < (SHORT_DURATION_THRESHOLD * (leg.origin.length - 12))
                        );
                        
                        // Determine if the station name should be hidden with tooltip on hover
                        const shouldHideStationName = shouldAddMarginStationName && previousShouldAddMarginStationName && nextLegDuration < SHORT_DURATION_THRESHOLD;

                        // Store the current leg's margin status for the next iteration
                        previousShouldAddMarginStationName = shouldAddMarginStationName;

                        // Prepare class name for station name
                        const stationNameClass = `station-name ${shouldHideStationName & !isMobile ? 'hidden' : ''} ${shouldAddMarginStationName & !isMobile ? 'extra-margin' : ''}`;

                        // Update the tripHasHiddenStationNames flag
                        if (shouldHideStationName) {
                          tripHasHiddenStationNames = true;
                        }

                        // Map the product types to something user-understandable
                        const trainTypeMap = {
                          regional: "Stoptrein",
                          national: "Intercity",
                          regionalExpress: "Sneltrein",
                          nationalExpress: "Hogesnelheidstrein",
                          ferry: "Veerboot",
                          suburban: "Stedelijk vervoer",
                          "N/A": "Ander vervoer",
                          //"N/A": null, // Hide "N/A"
                        };
                        
                        // Component to display the translated train type
                        const TrainTypeDisplay = ({ product }) => {
                          const trainType = trainTypeMap[product];
                        
                          if (!trainType) {
                            console.log(product); // TODO: remove after some time
                            return null; // Hide if no translation is available
                          }

                          if (leg.train_number?.startsWith('NJ ') || (leg.operator).split(',', 1)[0] === "European Sleeper" || (leg.operator).split(',', 1)[0] === "Urlaubs-Express") {
                            return <span>Nachttrein</span>;
                          }
                        
                          return <span>{trainType}</span>;
                        };                      

                        return (
                          <div className="journey-leg" key={legIndex} style={{ width: isMobile ? '100%' : `${legWidth}%` }}>
                            <div className={`circle ${transferClass}`} style={{
                              ...circleStyle,
                              '--transfer-height': `${transferHeight}px`
                            }}>
                              {/* Station name */}
                              <div className={stationNameClass} title={shorterStationName(leg.origin)}>
                                {shorterStationName(leg.origin)}
                              </div>
                              {/* Transfer details */}
                              {!isWalking && legIndex > 0 && legIndex < trip.legs.length && transfer.formattedTime !== "0m" && (
                                <div className={`transfer-details ${transfer.colorClass}`}>
                                  <div className="transfer-icon"></div>
                                  <div className="transfer-time">
                                    {transfer.formattedTime}
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* Line between stations */}
                            {legIndex < trip.legs.length && (
                              <div
                                className={isWalking ? "solid-line" : "dashed-line"}
                                style={{ 
                                  height: isMobile ? `${lineHeight}px` : '1px',
                                  '--line-height': `${lineHeight}px`
                                }}
                              ></div>
                            )}
                            {/* Leg details */}
                            <div
                              className={`leg-details ${isWalking ? 'is-walking' : ''}`}
                              style={
                                isMobile
                                  ? transferDetailsTop > 25
                                    ? {
                                        opacity: 1,
                                        position: 'absolute',
                                        top: `${(legDurationRatio + 0.25) * 100 + 0.75 * (transferHeight - 25) + 15}px`,
                                        left: '35px', // Adjust as needed
                                        transform: 'translateY(-50%)', // Centers the div vertically
                                      }
                                    : {
                                        opacity: 1,
                                        position: 'absolute',
                                        top: `${transferDetailsTop + 15 + 0.75 * (transferHeight - 10)}px`,
                                        left: '42px', // Adjust as needed
                                        transform: 'translateY(-50%)', // Centers the div vertically
                                        padding: '0',
                                        border: '0',
                                        boxShadow: 'none',
                                        fontSize: '0.8rem',
                                        background: 'none',
                                      }
                                  : moveLabelRight
                              }
                            >
                              {isWalking ? (
                                <div className="walking-icon">🚶</div>
                              ) : (
                                <>
                                  {leg.operator.includes("Nederlandse Spoorwegen") ? (
                                    <img src={`${baseUrl}/icons/nederlandsespoorwegen.png`} alt="Nederlandse Spoorwegen" className="operator-icon" />
                                  ) : (
                                    <img src={`${baseUrl}/icons/${(leg.operator).split(',', 1)[0].replace(/\s+/g, '').toLowerCase()}.png`} alt={(leg.operator).split(',', 1)[0]} className="operator-icon" />
                                  )}
                                </>
                              )}
                              <div className="leg-duration">{calculateDurationPretty(leg.departure, leg.arrival)}</div>
                              <div className="leg-product"><TrainTypeDisplay product={leg.product} /></div>
                            </div>
                          </div>
                        );
                      })}
                      <div className="circle">
                        <div className="station-name">{shorterStationName(trip.legs[trip.legs.length - 1].destination)}</div>
                      </div>
                    </div>
                    <div className="trip-time">
                      {new Date(trip.arrival).getDate() !== new Date(trip.departure).getDate() && <span className="next-day-icon">☾</span>}
                      <span className="trip-time-city">{shorterStationName(trip.legs[trip.legs.length - 1].destination)}</span>
                      {formatTime(trip.arrival)}
                      {new Date(trip.arrival).getDate() !== new Date(trip.departure).getDate() && <span className="next-day">Volgende dag</span>}
                    </div>

                    <div className="vertical-dash"></div>
                    <div className="trip-price">
                      {!loadingAvailability[compositeKey] ? (
                        <>
                          {trip.price && trip.price.amount > 0 && availableTrips[compositeKey] ? (
                            <>
                            {containsSignificantSleeperTrain & trip.legs.some(leg => "European Sleeper".includes((leg.operator).split(',', 1)[0])) ? (
                              <>
                              {trip.legs.length === 1 ? (
                                <table className="trip-price-table-nighttrain">
                                  <tbody>
                                    <tr>
                                      <td>
                                        {
                                          !isNaN(trip.price["nighttrain-prices"]["seat-price"]) 
                                            ? (
                                              <span className="trip-price-amount nighttrain">
                                                €{Math.floor(trip.price["nighttrain-prices"]["seat-price"]).toLocaleString()}
                                              </span>
                                            )
                                            : (
                                              <span className="trip-price-amount nighttrain soldout">
                                                ×
                                              </span>
                                            )
                                        }
                                      </td>
                                      <td>
                                        {
                                          !isNaN(trip.price["nighttrain-prices"]["couchette-5-price"]) 
                                            ? (
                                              <span className="trip-price-amount nighttrain">
                                                €{Math.floor(trip.price["nighttrain-prices"]["couchette-5-price"]).toLocaleString()}
                                              </span>
                                            )
                                            : (
                                              <span className="trip-price-amount nighttrain soldout">
                                                ×
                                              </span>
                                            )
                                        }
                                      </td>
                                      <td>
                                        {
                                          !isNaN(trip.price["nighttrain-prices"]["berth-triple-price"]) 
                                            ? (
                                              <span className="trip-price-amount nighttrain">
                                                €{Math.floor(trip.price["nighttrain-prices"]["berth-triple-price"]).toLocaleString()}
                                              </span>
                                            )
                                            : (
                                              <span className="trip-price-amount nighttrain soldout">
                                                ×
                                              </span>
                                            )
                                        }
                                      </td>
                                    </tr>
                                    <tr>
                                      <td><img src={`${baseUrl}/icons/seat.png`} alt="Zitplaats" /></td>
                                      <td><img src={`${baseUrl}/icons/bunk.png`} alt="Couchette" /></td>
                                      <td><img src={`${baseUrl}/icons/bed.png`} alt="Slaapwagen" /></td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : (
                                <span className="price-not-available">Deze route wordt niet op één ticket verkocht</span>
                              )}
                              </>
                            ) : (
                              <div className="trip-price-amount">
                                €{Math.floor(trip.price.amount).toLocaleString()}<sup>,{(trip.price.amount % 1).toFixed(2).substring(2)}</sup>
                              </div>
                            )}
                            </>
                          ) : (
                            <>
                            {containsSignificantSleeperTrain ? (
                              <>
                              {vendors && vendors.length > 0 ? (
                                <span className="price-differs">Prijs verschilt per accommodatietype. Klik op Boeken voor de prijs.</span>
                              ) : (
                                <span className="price-not-available">Deze route wordt niet op één ticket verkocht</span>
                              )}
                              </>
                            ) : (
                              <>
                              {trip.legs.some(leg => noPriceAPIOperators.includes((leg.operator).split(',', 1)[0])) ? (
                                <>
                                {vendors && vendors.length > 0 ? (
                                  <span className="price-not-available">Klik op Boeken voor de prijs</span>
                                ) : (
                                  <span className="price-not-available">Er lijkt iets mis te gaan met deze route, of niemand verkoopt dit ticket.</span>
                                )}
                                </>
                              ) : (
                                <span className="price-not-available">⌛ Prijs nog niet beschikbaar</span>
                              )}
                              </>
                            )}
                            </>
                          )}

                          <div className="price-terms">
                            {(() => {
                              const totalPassengers = passengerCounts.adults + 
                                passengerCounts.babies + 
                                passengerCounts.children4to5 + 
                                passengerCounts.children6to11 + 
                                passengerCounts.children12to14 + 
                                passengerCounts.youth15 + 
                                passengerCounts.youth16to25 + 
                                passengerCounts.seniors;
                              
                              const isSingleAdult = passengerCounts.adults === 1 && totalPassengers === 1;
                              
                              return !(totalPassengers === 0 || isSingleAdult) && (
                                <span className="price-multi-passenger">prijs per volwassene,&nbsp;</span>
                              );
                            })()}
                            <span className="price-single">
                              {isViewingReturn ? "terugreis" : "heenreis"}
                              {returnDate && (
                                <button 
                                  className="switch-to-return"
                                  onClick={() => {
                                    handleSwapLocations();
                                    setIsViewingReturn(!isViewingReturn);
                                    setTravelDate(isViewingReturn ? returnDate : departureDate);
                                    resetState();
                                  }}
                                >
                                  (bekijk terugreis ↪)
                                </button>
                              )}
                            </span>
                          </div>

                          {vendors && vendors.length > 0 && (
                            <div className="trip-tickets">
                              <div className="ticket-label">
                                <span className="tickets">TICKETS</span>
                              </div>
                              <div className="vendor-list">
                                {vendors.map((vendor, idx) => {
                                  let plusNMoreOffers = "";
                                  const nMoreOffers = vendors.length - 2; // Changed from -1 to -2
                                  if (idx === 1 && nMoreOffers > 0) { // Changed from idx === 0 to idx === 1
                                    plusNMoreOffers = `+ Nog ${nMoreOffers} aanbieder`;
                                    if (nMoreOffers > 1) {
                                      plusNMoreOffers += "s";
                                    }
                                  }

                                  let vendorLogo = "";
                                  if (vendor === "NS") {
                                    vendorLogo = "nederlandsespoorwegen";
                                  } else if (vendor === "DB") {
                                    vendorLogo = "dbfernverkehrag";
                                  } else if (vendor === "European Sleeper") {
                                    vendorLogo = "europeansleeper";
                                  }

                                  const toggleOtherVendorsVisibility = () => {
                                    setVisibleVendorBoxes(prev => {
                                      const isVisible = !prev[compositeKey];
                                      const element = vendorBoxRefs.current[compositeKey];
                                      if (element) {
                                          if (isVisible) {
                                              element.style.height = `${element.scrollHeight}px`;
                                          } else {
                                              element.style.height = '0px';
                                          }
                                      }
                                      // Hide the 'more-offers' element after clicking
                                      setShowMoreOffers(prev => ({
                                        ...prev,
                                        [compositeKey]: false
                                      }));
                                      return {
                                          ...prev,
                                          [compositeKey]: isVisible
                                      };
                                    });
                                  };
                                  
                                  return (
                                    <React.Fragment key={`fragment-${compositeKey}-${idx}`}>
                                      <div
                                          key={`offer-${compositeKey}-${idx}-${vendor}`}
                                          className={`vendor-box ${idx <= 1 ? 'auto-expanded' : visibleVendorBoxes[compositeKey] ? 'expanded' : ''}`}
                                          ref={el => vendorBoxRefs.current[compositeKey] = el}
                                      >
                                        {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                        <a href={bookingUrls[vendor]} target="_blank" rel="sponsored nofollow">
                                          <div className="vendor-logo-container">
                                            <img src={`${baseUrl}/icons/${vendorLogo}.png`} alt="Vendor Logo" className="vendor-logo" />
                                          </div>
                                          <div className="book-text-container">
                                            <span className="book-text">Boeken</span>
                                          </div>
                                        </a>
                                        {vendor === 'DB' && trip.via && (
                                          <div className="experimental-notice">
                                            Experimentele functie - Controleer de route op de DB-website!
                                          </div>
                                        )}
                                      </div>
                                      {showMoreOffers[compositeKey] !== false && (
                                        <div className="more-offers" onClick={() => toggleOtherVendorsVisibility(compositeKey)}>{plusNMoreOffers}</div>
                                      )}
                                    </React.Fragment>
                                  )
                                })}
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <div>Loading...</div>
                      )}
                    </div>
                    <div className="trip-summary">
                      <div className="summary-item">
                        <span className="desktop-only">Reisduur: </span>
                        <span className="mobile-only">⏱️ </span>
                        <strong>{formatDuration(trip)}</strong>
                      </div>
                      <div className="summary-item">
                        <span className="desktop-only">Overstappen: </span>
                        <span className="mobile-only">🔄 </span>
                        <strong>{trip.legs.length - 1}</strong>
                      </div>
                      <div className="summary-item complexity-indicator">
                        {(trip.complexity?.value || 1) > 1 ? (
                          <div 
                            className="complexity-clickable"
                            title={trip.complexity?.tooltip}  // Keep existing desktop tooltip
                            onClick={(e) => {
                              e.stopPropagation();
                              setMobileTooltipText(trip.complexity?.tooltip);
                              setMobileTooltipVisible(true);
                            }}
                          >
                            <span className={`complexity-dot complexity-dot-${trip.complexity?.value || 1}`}></span>
                            <span className="complexity-label">{getComplexityLabel(trip.complexity?.value || 1)}</span>
                            <span className="complexity-tooltip">ⓘ</span>
                          </div>
                        ) : (
                          <>
                            <span className={`complexity-dot complexity-dot-${trip.complexity?.value || 1}`}></span>
                            <span className="complexity-label">{getComplexityLabel(trip.complexity?.value || 1)}</span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="trip-remarks">
                      {(() => {
                        const hasEuropeanSleeperLeg = trip.legs.some(leg => 
                          (leg.operator).split(',', 1)[0] === "European Sleeper"
                        );
                        const isMultiLegJourney = trip.legs.length > 1;
                        const shouldShowWarning = hasEuropeanSleeperLeg && isMultiLegJourney;

                        // Find the European Sleeper leg
                        const europeanSleeperLeg = trip.legs.find(leg => 
                          (leg.operator).split(',', 1)[0] === "European Sleeper"
                        );

                        // Determine the station name based on the viewing direction
                        const europeanSleeperStation = isViewingReturn 
                          ? europeanSleeperLeg ? europeanSleeperLeg.destination : "het European Sleeper-station"
                          : europeanSleeperLeg ? europeanSleeperLeg.origin : "het European Sleeper-station";

                        return shouldShowWarning && (
                          <div className="remark">
                            &#9432; European Sleeper verkoopt alleen rechtstreekse tickets. Overweeg om een los kaartje {isViewingReturn ? 'vanaf' : 'tot aan'} {europeanSleeperStation} te kopen.
                            {!isViewingReturn && " Zorg wel dat je daar op tijd bent, aangezien de overstap dan voor eigen risico komt."}
                          </div>
                        );
                      })()}
                      {containsSignificantSleeperTrain && (
                        <div className="remark">&#9432; Deze reis bevat een slaaptrein. Lees onze <a href="/hoe-werkt-een-nachttrein/" target="_blank">informatie over nachttreinen</a>.</div>
                      )}
                      {tripHasHiddenStationNames && (
                        <div className="remark">&#9432; We hebben enkele stationsnamen moeten verbergen in het overzicht vanwege de beschikbare ruimte.</div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      {/* Add this at the bottom, just before the final closing div */}
      <div className="trip-disclaimer">
        Getoonde resultaten enkel ter inspiratie. Controleer altijd voor het boeken alle gegevens, zoals datum, tijden, route, reisgezelschap en ticketvoorwaarden.
      </div>

      {/* Add this at the bottom of your component, before the closing tag */}
      {mobileTooltipVisible && (
        <div className="mobile-tooltip">
          {mobileTooltipText}
        </div>
      )}
    </div>
  );
};

const formatTime = (time) => {
  const date = new Date(time);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

// Helper function to safely format the date in a well-readable shorthand notation
function getFormattedDateShorthand(departureDate) {
  if (!departureDate || typeof departureDate === 'object') {
    return 'No date available';
  }

  try {
    const parsedDate = new Date(convertToISOFormat(departureDate));
    
    // Check if the parsedDate is a valid Date
    if (isNaN(parsedDate.getTime())) {
      throw new Error('Invalid date');
    }

    const currentYear = new Date().getFullYear();
    const departureYear = parsedDate.getFullYear();

    if (departureYear === currentYear) {
      return format(parsedDate, 'd') + ' ' + monthNameInDutch(parsedDate);
    } else {
      return format(parsedDate, 'dd-MM-yyyy');
    }
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid date';
  }
}

function convertToISOFormat(dateStr) {
  // Check if dateStr is a string
  if (typeof dateStr !== 'string') {
    console.error(`Input must be a string: ${dateStr}`);
    return;
  }

  // Split the date string into components
  const parts = dateStr.split('-');

  // Validate the format (DD-MM-YYYY)
  if (parts.length !== 3) {
    console.error('Invalid date format. Expected format is DD-MM-YYYY');
    return;
  }

  const [day, month, year] = parts;

  // Validate that day, month, and year are numbers and within valid ranges
  if (
    isNaN(day) || isNaN(month) || isNaN(year) || 
    month < 1 || month > 12 || 
    day < 1 || day > 31 || 
    year.length !== 4
  ) {
    console.error('Invalid date components. Ensure DD, MM, and YYYY are valid numbers');
    return;
  }

  // Pad month and day with leading zeros if necessary
  const paddedMonth = month.padStart(2, '0');
  const paddedDay = day.padStart(2, '0');

  // Return the date in ISO format (YYYY-MM-DD)
  return `${year}-${paddedMonth}-${paddedDay}`;
}

const calculateDurationPretty = (start, end) => {
  let startTime, endTime;

  // Check if start is a number (milliseconds since the epoch or duration)
  if (typeof start === 'number') {
    if (start > 1000000000000) { // Likely a timestamp in milliseconds
      startTime = new Date(start);
    } else {
      // Handle this as a time difference, assuming end is also a number
      startTime = 0;
      endTime = start;
    }
  } else {
    // Otherwise, assume start is a date string
    startTime = new Date(start);
  }

  // Check if end is a number (milliseconds since the epoch or duration)
  if (typeof end === 'number') {
    if (end > 1000000000000) { // Likely a timestamp in milliseconds
      endTime = new Date(end);
    } else if (typeof startTime === 'number') {
      // Handle this as a time difference if startTime was set to 0
      endTime = end;
    } else {
      // Handle mismatch (e.g., start as a date and end as a duration in ms)
      console.error('Mismatched types for start and end. Cannot calculate duration.');
      return 'Invalid inputs';
    }
  } else {
    // Otherwise, assume end is a date string
    endTime = new Date(end);
  }

  // Check if parsing resulted in valid dates
  if (isNaN(startTime) || isNaN(endTime)) {
    console.error('Invalid start or end time');
    return 'Invalid time';
  }

  // Calculate duration
  const duration = (endTime - startTime) / (1000 * 60); // duration in minutes
  const hours = Math.floor(duration / 60);
  const minutes = Math.round(duration % 60);
  
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
};

const calculateDuration = (start, end) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  return endTime - startTime;
};  

const getWidth = (duration, totalDuration) => {
  if (totalDuration <= 0) {
    console.error("Invalid total duration:", totalDuration);
    return 0;
  }
  return (duration / totalDuration) * 100;
};

const calculateTransferTime = (arrival, departure) => {
  return new Date(departure) - new Date(arrival);
};

const calculateTransferTimePretty = (arrival, departure) => {
  if (!arrival || !departure) { return }
  const arrivalTime = new Date(arrival);
  const departureTime = new Date(departure);
  const transferDuration = (departureTime - arrivalTime) / (1000 * 60); // duration in minutes
  const hours = Math.floor(transferDuration / 60);
  const minutes = transferDuration % 60;
  const formattedTime = `${hours ? hours + 'h ' : ''}${minutes}m`;

  let colorClass = '';
  if (transferDuration < 10) {
    colorClass = 'short-transfer';
  } else if (transferDuration > 45) {
    colorClass = 'long-transfer';
  }

  return { formattedTime, colorClass };
};

const shorterStationName = (name) => {
  return name.replace(/\b(Centraal|Central|Centrum|Hbf|Station)\b/gi, '').replace(/\(.*?\)/g, '').trim();
};

// Function to calculate similarity between two strings
const similarity = (s1, s2) => {
  let longer = s1;
  let shorter = s2;
  if (s1.length < s2.length) {
    longer = s2;
    shorter = s1;
  }
  const longerLength = longer.length;
  if (longerLength === 0) {
    return 1.0;
  }
  return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
};

const editDistance = (s1, s2) => {
  s1 = s1.toLowerCase();
  s2 = s2.toLowerCase();

  const costs = [];
  for (let i = 0; i <= s1.length; i++) {
    let lastValue = i;
    for (let j = 0; j <= s2.length; j++) {
      if (i === 0) {
        costs[j] = j;
      } else {
        if (j > 0) {
          let newValue = costs[j - 1];
          if (s1.charAt(i - 1) !== s2.charAt(j - 1)) {
            newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
          }
          costs[j - 1] = lastValue;
          lastValue = newValue;
        }
      }
    }
    if (i > 0) {
      costs[s2.length] = lastValue;
    }
  }
  return costs[s2.length];
};

const hardcodedMappings = {
  "Koebenhavn H": "DKKHX",
  // Add other hardcoded mappings here as needed
};

// Function to get NS encoding from station name
const getNSEncoding = (stationName) => {
  // Standardize the input by trimming and converting to lowercase
  const standardizedInput = stationName.trim().toLowerCase();

  // Check the hardcoded mappings first
  if (hardcodedMappings[stationName]) {
    return hardcodedMappings[stationName];
  }

  // Helper function to normalize station names for comparison
  const normalizeStationName = (name) => {
    return name
      .toLowerCase()
      .replace(/[.\s-]+/g, ' ') // Replace dots, spaces, and hyphens with a single space
      .replace(/\s*international\s*/g, '') // Remove "international"
      .replace(/\s*centraal\s*/g, '') // Remove "centraal"
      .replace(/\s*central\s*/g, '') // Remove "central"
      .replace(/\s*station\s*/g, '') // Remove "station"
      .replace(/\s*hauptbahnhof\s*/g, '') // Remove "hauptbahnhof"
      .replace(/\s*hbf\s*/g, '') // Remove "hbf"
      .replace(/\s*bahnhof\s*/g, '') // Remove "bahnhof"
      .trim();
  };

  // Normalize the input station name
  const normalizedInput = normalizeStationName(standardizedInput);

  // First try exact match with normalized names
  let station = stationMappings.find(st => 
    normalizeStationName(st.name) === normalizedInput
  );

  // If no exact match, try fuzzy matching with normalized names
  if (!station) {
    station = stationMappings.find(st => {
      const normalizedMapping = normalizeStationName(st.name);
      return similarity(normalizedMapping, normalizedInput) > 0.8;
    });
  }

  // Debugging Logs
  if (!station) {
    console.warn(`No mapping found for station "${stationName}". Returning "NS-MAPPING-ERROR".`);
  }

  // Return the mapped code if found, or fallback to "NS-MAPPING-ERROR"
  return station ? station.code : "NS-MAPPING-ERROR";
};

function slugify(text) {
  return text
    .normalize('NFD') // Normalize the string to decompose combined letters
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove special characters except spaces and hyphens
    .trim() // Remove leading and trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .toLowerCase(); // Convert to lowercase
}

const monthNameInDutch = (date) => {
  // Ensure the date is a valid Date object
  const validDate = new Date(date);
  if (isNaN(validDate)) {
    return;
  }

  // Create an Intl.DateTimeFormat object for Dutch locale
  const formatter = new Intl.DateTimeFormat('nl-NL', { month: 'long' });

  // Format the month
  const monthName = formatter.format(validDate);

  return monthName;
};

// Modify the function signature to accept setShortTransferStations
const calculateComplexity = (trip, shortTransferStationsRef) => {
  let complexity = 1;
  let reasons = [];
  let currentShortTransfers = [];

  // Check for London St. Pancras
  const hasLondon = trip.legs.some(leg => 
    leg.origin === "London St. Pancras International" || 
    leg.destination === "London St. Pancras International"
  );
  
  if (hasLondon && complexity < 3) {
    complexity++;
    reasons.push("Reis in/uit Schengengebied");
  }

  // Check for night departure/arrival
  const departureHour = new Date(trip.departure).getHours();
  const arrivalHour = new Date(trip.arrival).getHours();
  const isNightTime = (hour) => hour >= 0 && hour < 6;
  
  if ((isNightTime(departureHour) || isNightTime(arrivalHour)) && complexity < 3) {
    complexity++;
    reasons.push(isNightTime(departureHour) && isNightTime(arrivalHour) 
      ? "Vertrek en aankomst in de nacht"
      : isNightTime(departureHour) 
        ? "Vertrek in de nacht" 
        : "Aankomst in de nacht"
    );
  }

  // Define Dutch operators
  const dutchOperators = ['Nederlandse Spoorwegen', 'Arriva Nederland', 'RRReis', 'Blauwnet'];
  const localTrainTypes = ['regional', 'regionalExpress', 'suburban'];
  
  let foreignTransfers = 0;
  let hasLongTransfer = false;
  
  for (let i = 1; i < trip.legs.length; i++) {
    const fromOperator = trip.legs[i-1].operator.split(',')[0];
    
    // Check transfer time
    const transferTime = (new Date(trip.legs[i].departure) - new Date(trip.legs[i-1].arrival)) / (1000 * 60);
    if (transferTime > 180 && !hasLongTransfer && complexity < 3) {  // 3 hours = 180 minutes
      complexity = 3;  // Jump straight to 3
      hasLongTransfer = true;
      reasons.push("Overstap langer dan 3 uur");
    } else if (transferTime > 90 && !hasLongTransfer && complexity < 3) {
      complexity++;
      hasLongTransfer = true;
      reasons.push("Overstap langer dan 1,5 uur");
    }
    
    // Check for complex international transfers
    if ((!dutchOperators.includes(fromOperator) || 
         trip.legs[i-1].train_number?.includes('ICE') ||
         /^IC \d{3}$/.test(trip.legs[i-1].train_number || '')) && 
        !localTrainTypes.includes(trip.legs[i].product) &&
        transferTime < 35 &&
        transferTime >= 1) {
      
      // If there is a previous leg and it was a Transfer, look at the leg before that
      if (i >= 2 && trip.legs[i-1].train_number === "Transfer") {
        // Only count as complex if leg before Transfer was non-Dutch
        const operatorBeforeTransfer = trip.legs[i-2].operator.split(',')[0];
        if (!dutchOperators.includes(operatorBeforeTransfer)) {
          foreignTransfers++;
          // Add station tracking here
          currentShortTransfers.push(trip.legs[i-2].destination);
        }
      } else {
        foreignTransfers++;
        // Add station tracking here
        currentShortTransfers.push(trip.legs[i-1].destination);
      }
    }
  }

  // Add this block after the loop
  if (currentShortTransfers.length > 0) {
    currentShortTransfers.forEach(station => {
      shortTransferStationsRef.current.set(
        station, 
        (shortTransferStationsRef.current.get(station) || 0) + 1
      );
    });
  }
  
  if (foreignTransfers > 0) {
    complexity += Math.min(foreignTransfers, 2); // Add max 2 to complexity
    reasons.push(`Krappe overstap${foreignTransfers > 1 ? 'pen' : ''} buiten Nederland`);
  }

  // Check for walking sections, excluding Eurostar transfers
  const hasWalking = trip.legs.some(leg => 
    leg.product === "N/A" && 
    !(leg.origin.includes("Eurostar") || leg.destination.includes("Eurostar"))
  );
  
  if (hasWalking && complexity < 3) {
    complexity++;
    reasons.push("Gedeelte met ander vervoer");
  }

  // Count total number of transfers
  const totalTransfers = trip.legs.length - 1;
  
  // Add 0.2 for each transfer that wasn't already counted
  if (totalTransfers > 0) {
    const transferComplexity = 0.2 * totalTransfers;
    complexity += transferComplexity;
    
    if (transferComplexity >= 2) {
      reasons.push("Erg veel overstappen");
    } else if (transferComplexity >= 1) {
      reasons.push("Veel overstappen");
    }
  }

  // Format reasons with proper Dutch conjunction
  const formatReasons = (reasons) => {
    if (reasons.length === 0) return '';
    if (reasons.length === 1) return reasons[0];
    if (reasons.length === 2) return `${reasons[0]} en ${reasons[1]}`;
    return reasons.slice(0, -1).join(', ') + ' en ' + reasons[reasons.length - 1];
  };

  // Cap complexity at 3 and round down to integer
  complexity = Math.floor(Math.min(complexity, 3));
  
  return {
    value: complexity,
    tooltip: formatReasons(reasons)
  };
};

export default TripOptions;
